
.main-wrapper {
  height: 82%;
  padding: 0 30px ;    
   .title{
      text-align: center;
      padding-bottom: 30px;
      .titleData{
          font-weight: bold; 
      }
  }
  .cont{
      height: 100%;
      box-sizing: border-box;
      padding: 0px 30px 0 10px;
      .el-scrollbar {
          margin: 20px;
          height: calc(100% - 120px);
      }

      ::v-deep .el-scrollbar__wrap {
          height: 100%;
          overflow-x: hidden;

          .el-scrollbar__view {
              height: 100%;
          }
      }
          
      .main{
          margin: 0 auto;
          width: 1300px;
          height: 100%;
         .main-title{
              flex: 1;
              display: flex;
              .line{
                  width: 10%;
                  text-align: center;
                  padding: 0 10px;
              }
         }
         .main-foot{
              flex: 1;
              display: flex;
              text-align: center;
              padding-top: 20px;
              .line{
                  display: flex;
                  display: inline-block;
                  line-height: 45px;
                  width: 10%;
                  padding: 0 10px;
              }
              .line:nth-child(1){
                   text-align: right;
              }
         }
      }
      .footer-button{
          text-align: center;
          padding-top: 50px;
          padding-bottom: 30px;
      }
  }
 
}
